/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/reset.css"
import "./src/styles/global.css"
import { polyfillAddForEachToNodeList } from "./src/utils/utils"
require("prismjs/themes/prism-tomorrow.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")
require("prismjs/plugins/command-line/prism-command-line.css")

polyfillAddForEachToNodeList()
