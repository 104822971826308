// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-page-blog-post-page-js": () => import("./../../../src/templates/BlogPostPage/BlogPostPage.js" /* webpackChunkName: "component---src-templates-blog-post-page-blog-post-page-js" */),
  "component---src-templates-simple-page-simple-page-js": () => import("./../../../src/templates/SimplePage/SimplePage.js" /* webpackChunkName: "component---src-templates-simple-page-simple-page-js" */),
  "component---src-templates-simple-share-page-simple-share-page-js": () => import("./../../../src/templates/SimpleSharePage/SimpleSharePage.js" /* webpackChunkName: "component---src-templates-simple-share-page-simple-share-page-js" */)
}

